/** @jsx jsx */
import {
  extractPartnerIdsForCharacteristics,
  getCertificationsForId,
} from '@bottlebooks/gatsby-theme-event-pages/src/helpers/brandCertifications';
import ExhibitorPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ExhibitorTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { previous, next } = data;
  const { site, exhibitor, event } = afterQuery(data);
  return (
    <ExhibitorPage
      {...pageContext}
      event={event}
      exhibitor={exhibitor}
      products={exhibitor && exhibitor.products}
      previous={previous}
      next={next}
      siteTitle={site.siteMetadata.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  fragment predhomme_ExhibitorTemplate_Bottlebooks_Event on Bottlebooks_Event {
    collectionId: eventId
    registeredBrands(filter: { companyId: { eq: $exhibitorId } }) {
      nodes {
        ...bb_ExhibitorPage_RegisteredBrands
      }
    }
    registeredProducts(filter: { partnerId: { eq: $exhibitorId } }) {
      ...brandCertifications_RegisteredProductConnection
    }
    registrations(filter: { companyId: { eq: $exhibitorId } }) {
      nodes {
        ...bb_Registration
        ...bb_ExhibitorPage_SingleRegistration
        profile {
          logo {
            publicId
          }
        }
      }
    }
    exhibitor(exhibitorId: $exhibitorId, returnNullWhenNotFound: true) {
      exhibitorId
      ...bb_ExhibitorPage
      products {
        nodes {
          producer {
            name
          }
          name
          shortName
          ... on Bottlebooks_Wine {
            vintage
            grapeVarieties {
              varietyName
              percentage
            }
          }
          country
        }
      }
    }
  }
  query predhomme_ExhibitorTemplate(
    $id: String!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      oregon: event(eventId: "62ed79aa123f1410cadbbc68", locale: $locale) {
        ...predhomme_ExhibitorTemplate_Bottlebooks_Event
      }
      newZealand: event(eventId: "62fb58731492c721f587c11f", locale: $locale) {
        ...predhomme_ExhibitorTemplate_Bottlebooks_Event
      }
    }

    exhibitor(id: { eq: $id }) {
      ...ExhibitorTemplate_Exhibitor
    }
    previous: exhibitor(id: { eq: $previousId }) {
      ...ChangeExhibitorLink
    }
    next: exhibitor(id: { eq: $nextId }) {
      ...ChangeExhibitorLink
    }
    ...ExhibitorTemplate_Query
  }

  fragment ExhibitorTemplate_Exhibitor on Exhibitor {
    ...ExhibitorPage
    event {
      ...ExhibitorPage_Event
    }
  }

  fragment ExhibitorTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, exhibitor, site }) {
  const events = Object.values(bottlebooks);
  const bb_exhibitor = mergeBbExhibitor(events);
  // TODO: apply same merging logic to registrations and registered brands
  const bb_event = events[0];

  const bb_registration = bb_event?.registrations?.nodes?.[0];
  const bb_registeredBrands = mergeRegisteredBrands(events);
  const bb_products = bb_exhibitor?.products?.nodes;
  const products = exhibitor.products?.map((product, index) => {
    if (!bb_products?.length) return product;
    return {
      ...product,
      ...bb_products[index],
      ...bb_registration?.registeredProducts?.nodes?.[index]?.product,
    };
  });
  const enhancedExhibitor = deepMerge(
    {},
    exhibitor,
    bb_exhibitor,
    // bb_registration,
    // bb_registration.profile,
    getCertificationsForId({
      extractIdsFn: extractPartnerIdsForCharacteristics,
      id: exhibitor.exhibitorId,
      event: bb_event,
    }),
    {
      brands: bb_registeredBrands,
      products: bb_exhibitor?.products?.nodes,
    }
  );
  return { site, event, exhibitor: enhancedExhibitor };
}

function mergeBbExhibitor(events) {
  const exhibitor = events.reduce((acc, event) => {
    const { exhibitor, registration } = event;
    if (!exhibitor) return acc;

    const { exhibitorId } = exhibitor;

    acc[exhibitorId] = {
      ...exhibitor,
      collectionIds: [event.collectionId],
      ...registration,
      ...registration?.profile,
      standNames: [
        ...(acc[exhibitorId]?.standNames || []),
        {
          collectionId: event.collectionId,
        },
      ],
      products: {
        nodes: [
          ...(acc[exhibitorId]?.products?.nodes || []),
          ...exhibitor.products.nodes.map((product) => ({
            ...product,
            collectionId: event.collectionId,
            standNames: [{ collectionId: event.collectionId }],
          })),
        ],
      },
    };
    return acc;
  }, {});

  return Object.values(exhibitor)[0];
}

function mergeRegisteredBrands(events) {
  const registeredBrands = events.reduce((acc, event) => {
    const { registeredBrands } = event;
    const registeredBrandNodes = registeredBrands?.nodes || [];

    registeredBrandNodes.map((registeredBrand) => {
      const { brandId } = registeredBrand;
      if (acc[brandId]) {
        acc[brandId].collectionIds.push(event.collectionId);
        acc[brandId].standNames.push({
          collectionId: event.collectionId,
          standName: registeredBrand.stand?.name,
        });
      } else
        acc[brandId] = {
          ...registeredBrand,
          collectionIds: [event.collectionId],
          standNames: [
            {
              collectionId: event.collectionId,
              standName: registeredBrand.stand?.name,
            },
          ],
        };
    });
    return acc;
  }, {});

  return Object.values(registeredBrands);
}
